import React from 'react'
import Layout from '../layout/basic.js'

const CareersPage = () => (
  <Layout currentName="careers">
    <section className="careers">

      <article className="hero header-hero has-text-centered">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">採用情報</h1>
            <h2 className="subtitle">CAREERS</h2>
          </div>
        </div>
      </article>

      <article className="main-contents has-text-centered">
        <div className="container columns is-multiline">
          <div className="column is-12">
            <div className="columns">
              <div className="column is-4 is-offset-4">
                <p>現在、募集はしておりません。</p>
                <p>募集の際はこちらでお知らせ致します。</p>
              </div>
            </div>
          </div>
        </div>
      </article>

    </section>
  </Layout>
);
export default CareersPage;
